export class SearchFilter {
  public key = ""
  public value: string | undefined = ""
  public operator = ""

  constructor(data: any = {}) {
    this.key = data.key || ""
    this.value = data.value || ""
    this.operator = data.operator || ""
  }
}
