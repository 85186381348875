import React, { useEffect, useState } from "react"
import {
  Order,
  SearchFilter,
  SearchParams,
  SearchResultInfo,
} from "../../../entities"
import { Link as RouterLink } from "react-router-dom"
import {
  Button,
  Flex,
  Input,
  Select,
  Link as ChakraLink,
  Text,
  FormControl,
} from "@chakra-ui/react"
import InputMask from "react-input-mask"
import Layout from "../../../components/layout/Layout"
import Loader from "../../../components/Loader/Loader"
import Breadcrumbs from "../../../components/layout/breadcrumbs/Breadcrumbs"
import DataTable from "../../../components/dataTable/DataTable"
import { OrderService } from "../../../services"
import Formatter from "../../../util/formatter"
import { BsCardHeading } from "react-icons/bs"
import { GrView } from "react-icons/gr"

export default function PaymentListPage() {
  const today = new Date()
  const day = ("0" + today.getDate()).slice(-2)
  const month = ("0" + (today.getMonth() + 1)).slice(-2)

  const [orders, setOrders] = useState<Order[]>([])
  const [resultInfo, setResultInfo] = useState(
    new SearchResultInfo({ pageSize: 20 })
  )
  const [loading, setLoading] = useState(false)

  const [occurrenceFrom, setOccurrenceFrom] = useState(
    today.getFullYear() + "-" + month + "-" + day
  )
  const [occurrenceTo, setOccurrenceTo] = useState("")
  const [type, setType] = useState("")
  const [paymentMethod, setPaymentMethod] = useState("")
  const [licensePlate, setLicensePlate] = useState("")
  const [txId, setTxId] = useState("")

  useEffect(() => {
    load()
    // eslint-disable-next-line
  }, [])

  const getFilters = (): SearchFilter[] => {
    const filters: SearchFilter[] = []

    if (txId) {
      filters.push(
        new SearchFilter({
          key: "txId",
          value: txId,
          operator: "eq",
        })
      )
    }

    if (occurrenceFrom) {
      filters.push(
        new SearchFilter({
          key: "occurrenceFrom",
          value: occurrenceFrom,
          operator: "eq",
        })
      )
    }

    if (occurrenceTo) {
      filters.push(
        new SearchFilter({
          key: "occurrenceTo",
          value: occurrenceTo,
          operator: "eq",
        })
      )
    }

    if (type) {
      filters.push(
        new SearchFilter({ key: "type", value: type, operator: "eq" })
      )
    }

    if (paymentMethod) {
      filters.push(
        new SearchFilter({
          key: "paymentMethod",
          value: paymentMethod,
          operator: "eq",
        })
      )
    }

    if (licensePlate) {
      filters.push(
        new SearchFilter({
          key: "licensePlate",
          value: licensePlate,
          operator: "eq",
        })
      )
    }

    filters.push(
      new SearchFilter({ key: "status", value: "paid", operator: "eq" })
    )

    return filters
  }

  const load = async (page = 1) => {
    const filters = getFilters()
    console.log(filters)
    const searchParams = new SearchParams(filters, page, 10)

    setLoading(true)
    OrderService.search(searchParams)
      .then((result) => {
        setOrders(result.data)
        setResultInfo(result.info)
      })
      .finally(() => setLoading(false))
  }

  const renderType = (params: any) => {
    const type = Formatter.formatOrderType(params.type)
    if (params.chargesArr?.length > 0)
      return (
        <ChakraLink
          as={RouterLink}
          to={`/charge/${params.chargesArr[0].id}`}
          target="_blank"
        >
          {type}
        </ChakraLink>
      )
    else return type
  }

  const renderName = (params: any) => {
    if (params.user?.id)
      return (
        <ChakraLink
          as={RouterLink}
          to={`/customer/${params.user?.id}`}
          target="_blank"
        >
          {params.name}
        </ChakraLink>
      )
    else return params.name
  }

  const renderEmail = (params: any) => {
    if (params.user?.id)
      return (
        <ChakraLink
          as={RouterLink}
          to={`/customer/${params.user?.id}`}
          target="_blank"
        >
          {params.email}
        </ChakraLink>
      )
    else return params.email
  }

  const renderPhone = (params: any) => {
    if (params.user?.id)
      return (
        <ChakraLink
          as={RouterLink}
          to={`/customer/${params.user?.id}`}
          target="_blank"
        >
          {params.phone}
        </ChakraLink>
      )
    else return params.phone
  }

  const renderActions = (params: any) => {
    return (
      <ChakraLink as={RouterLink} to={`/payment/${params.id}`}>
        <Button
          bg="#E7ECFF"
          color="#00335B"
          transition="0.2s"
          _hover={{ bg: "#114B8F", color: "#fff" }}
        >
          <GrView />
        </Button>
      </ChakraLink>
    )
  }

  const columns = [
    // { field: "createdAtFormated", headerName: "Criação" },
    { field: "paidAtFormated", headerName: "Pago" },
    { renderCell: renderType, headerName: "Tipo" },
    { field: "paymentMethodFormated", headerName: "Forma de Pagamento" },
    { renderCell: renderName, headerName: "Nome" },
    { renderCell: renderEmail, headerName: "E-mail" },
    { renderCell: renderPhone, headerName: "Telefone" },
    { field: "totals.rateAmountFormated", headerName: "Valor" },
    { field: "totals.discountFormated", headerName: "Desconto" },
    { field: "totals.feeFormated", headerName: "Acréscimo" },
    { field: "totals.totalFormated", headerName: "Total" },
    { renderCell: renderActions, headerName: "Ações" },
  ]

  return (
    <Layout>
      {loading && <Loader />}
      <Flex
        w="100%"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        mb="3rem"
      >
        <Flex direction="column">
          <Breadcrumbs
            items={[
              { label: "Dashboard", link: "/" },
              { label: "Pagamentos", link: "/payment" },
            ]}
            additionalHeaderInfo={
              <>
                {/* <Link to="/payment/0"><button><AiOutlinePlus /></button></Link> */}
              </>
            }
          />
          <Flex gap="0.75rem" alignItems="center">
            <Flex fontSize="2rem" fontWeight="700" color="#114B8F">
              <BsCardHeading />
            </Flex>
            <Text
              fontSize={{
                base: "1.5rem",
                sm: "1.75rem",
                md: "2rem",
                lg: "2.25rem",
              }}
              fontWeight="700"
              color="#114B8F"
              fontFamily="DM Sans"
            >
              Pagamentos
            </Text>
          </Flex>
        </Flex>

        <Flex
          w="auto"
          ml="auto"
          alignSelf="flex-end"
          justifyContent={{
            base: "flex-end",
            sm: "flex-end",
            md: "flex-end",
            lg: "flex-end",
          }}
          gap="1.25rem"
          alignItems="flex-end"
          direction={{ base: "column", sm: "row", md: "row", lg: "row" }}
        >
          <FormControl
            w="100%"
            maxW={{ base: "100%", sm: "100%", md: "100%", lg: "648px" }}
            isInvalid={false}
            display="flex"
            justifyContent="flex-end"
            gap="0.5rem"
            flexWrap="wrap"
          >
            <Input
              maxW={{ base: "100%", sm: "158px", md: "158px", lg: "158px" }}
              size="sm"
              fontFamily="Raleway"
              fontSize="0.75rem"
              fontWeight="400"
              _placeholder={{ color: "#A2ACBD" }}
              placeholder="Período inicial"
              border="1px solid #E2E8F0"
              borderRadius="0.25rem"
              type="date"
              name="from"
              value={occurrenceFrom}
              onChange={(e) => setOccurrenceFrom(e.target.value)}
            />
            <Input
              maxW={{ base: "100%", sm: "158px", md: "158px", lg: "158px" }}
              size="sm"
              fontFamily="Raleway"
              fontSize="0.75rem"
              fontWeight="400"
              _placeholder={{ color: "#A2ACBD" }}
              placeholder="Até"
              border="1px solid #E2E8F0"
              borderRadius="0.25rem"
              type="date"
              name="to"
              value={occurrenceTo}
              onChange={(e) => setOccurrenceTo(e.target.value)}
            />
            <Input
              maxW={{ base: "100%", sm: "154px", md: "154px", lg: "154px" }}
              size="sm"
              fontFamily="Raleway"
              fontSize="0.75rem"
              fontWeight="400"
              placeholder="Placa"
              _placeholder={{ color: "#A2ACBD" }}
              border="1px solid #E2E8F0"
              borderRadius="0.25rem"
              type="text"
              name="licensePlate"
              textTransform="capitalize"
              as={InputMask}
              mask="aaa-9*99"
              maskChar={null}
              value={licensePlate}
              onChange={(e) => setLicensePlate(e.target.value.toUpperCase())}
            />

            <Input
              maxW={{ base: "100%", sm: "154px", md: "154px", lg: "154px" }}
              size="sm"
              fontFamily="Raleway"
              fontSize="0.75rem"
              fontWeight="400"
              placeholder="txID"
              _placeholder={{ color: "#A2ACBD" }}
              border="1px solid #E2E8F0"
              borderRadius="0.25rem"
              type="text"
              name="txId"
              textTransform="capitalize"
              value={txId}
              onChange={(e) => setTxId(e.target.value.toUpperCase())}
            />

            <Select
              maxW={{ base: "100%", sm: "120px", md: "120px", lg: "120px" }}
              size="sm"
              fontFamily="Raleway"
              fontSize="0.75rem"
              fontWeight="400"
              _placeholder={{ color: "#A2ACBD" }}
              placeholder="Status"
              border="1px solid #E2E8F0"
              borderRadius="0.25rem"
              value={type}
              onChange={(e) => setType(e.target.value)}
            >
              <option value="">Todos</option>
              <option value="charge">Cobranças</option>
              <option value="balance">Crédito</option>
            </Select>
            <Select
              maxW={{ base: "100%", sm: "204px", md: "204px", lg: "204px" }}
              size="sm"
              fontFamily="Raleway"
              fontSize="0.75rem"
              fontWeight="400"
              _placeholder={{ color: "#A2ACBD" }}
              placeholder="Método de pagamento"
              border="1px solid #E2E8F0"
              borderRadius="0.25rem"
              value={paymentMethod}
              onChange={(e) => setPaymentMethod(e.target.value)}
            >
              <option value="">Todos</option>
              <option value="credit-card">Cartão de Crédito</option>
              <option value="pix">Pix</option>
              <option value="cash">Dinheiro</option>
              <option value="balance">Crédito</option>
              <option value="vvp">Vale</option>
              <option value="credit-card-pinpad">
                Cartão de Crédito Totem
              </option>
              <option value="debit-card-pinpad">Cartão de Débito Totem</option>
            </Select>
          </FormControl>
          <Flex gap="1rem">
            <Button
              minW="95px"
              size="md"
              borderRadius="0.375rem"
              bg="#00335B"
              fontFamily="Raleway"
              fontSize="0.875rem"
              fontWeight="600"
              color="#fff"
              _hover={{ bg: "#00335B" }}
              onClick={() => load()}
            >
              Filtrar
            </Button>
          </Flex>
        </Flex>
      </Flex>
      <DataTable
        checkboxSelection={false}
        rows={orders}
        columns={columns}
        pagination={resultInfo}
        onPageChange={(page: number) => load(page)}
        loading={loading}
      />
    </Layout>
  )
}
