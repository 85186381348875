import { Fragment, useEffect, useState } from "react"
import { Order } from "../../../entities"
import { useParams, Link as RouterLink } from "react-router-dom"
import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
  Link as ChakraLink,
  Text,
  Button,
} from "@chakra-ui/react"
import Layout from "../../../components/layout/Layout"
import Loader from "../../../components/Loader/Loader"
import Breadcrumbs from "../../../components/layout/breadcrumbs/Breadcrumbs"
import { OrderService } from "../../../services"
import { BsCardHeading } from "react-icons/bs"
import theme from "../../../config/Themes"
import ReactInputMask from "react-input-mask"
import Formatter from "../../../util/formatter"
import DataTable from "../../../components/dataTable/DataTable"
import { GrView } from "react-icons/gr"
import { format } from "date-fns"

export default function PaymentDetailPage() {
  const params = useParams()

  const { id } = params

  const [loading, setLoading] = useState<boolean>(false)
  const [order, setOrder] = useState<Order | null>(null)

  const handleGetOrderById = () => {
    setLoading(true)
    OrderService.get(id)
      .then((order) => setOrder(order))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    if (id) handleGetOrderById()
  }, [id])

  const renderActions = (params: any) => {
    return (
      <div>
        <ChakraLink as={RouterLink} to={`/charge/${params.id}`}>
          <Button
            bg="#E7ECFF"
            color="#00335B"
            transition="0.2s"
            _hover={{ bg: "#114B8F", color: "#fff" }}
          >
            <GrView />
          </Button>
        </ChakraLink>
      </div>
    )
  }

  const renderCustomer = (params: any) => {
    return (
      <ChakraLink as={RouterLink} to={`/customer/${params.user?.id}`}>
        {params.user?.firstname}
      </ChakraLink>
    )
  }

  const columns = [
    { renderCell: renderCustomer, headerName: "Cliente", mWidth: "100%" },
    {
      field: "datetimeOccurrenceFormated",
      headerName: "Data de Ocorrência",
      mWidth: "55%",
    },
    { field: "licensePlate", headerName: "Placa", mWidth: "40%" },
    { field: "rateAmountFormated", headerName: "Tarifa", mWidth: "45%" },
    { field: "discountFormated", headerName: "Desconto", mWidth: "45%" },
    { field: "discountDufFormated", headerName: "DUF", mWidth: "45%" },
    { field: "discountDbtFormated", headerName: "DBT", mWidth: "45%" },
    { field: "feeFormated", headerName: "Acréscimo", mWidth: "45%" },
    { field: "totalFormated", headerName: "Total", mWidth: "45%" },
    { field: "plazaDescription", headerName: "Praça", mWidth: "45%" },
    { field: "statusFormated", headerName: "Status", mWidth: "30%" },
    {
      headerName: "Ações",
      renderCell: renderActions,
      mWidth: "65%",
      mAlign: "end",
      mDisplay: "none",
    },
  ]

  return (
    <Layout>
      {loading && <Loader />}
      <Flex
        w="100%"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        mb="3rem"
      >
        <Flex direction="column">
          <Breadcrumbs
            items={[
              { label: "Dashboard", link: "/" },
              { label: "Pagamentos", link: "/payment" },
              { label: "Detalhes do pagamento", link: `/payment/${id}` },
            ]}
          />
          <Flex gap="0.75rem" alignItems="center">
            <Flex fontSize="2rem" fontWeight="700" color="#114B8F">
              <BsCardHeading />
            </Flex>
            <Text
              fontSize={{
                base: "1.5rem",
                sm: "1.75rem",
                md: "2rem",
                lg: "2.25rem",
              }}
              fontWeight="700"
              color="#114B8F"
              fontFamily="DM Sans"
            >
              Detalhes do Pagamento
            </Text>
          </Flex>
        </Flex>

        <Flex w="100%" h="full" p="2rem" display="flex" flexDirection="column">
          <Text fontSize="2rem" fontWeight="600" color={theme.color.secondary}>
            Pagador
          </Text>

          <SimpleGrid
            alignItems="center"
            columns={{ base: 1, sm: 2, md: 2, lg: 3, xl: 4 }}
            spacing="1rem"
          >
            <FormControl>
              <FormLabel color={theme.color.tertiary}>E-mail</FormLabel>
              <Input type="text" name="email" value={order?.email} readOnly />
            </FormControl>
            <FormControl>
              <FormLabel color={theme.color.tertiary}>Nome</FormLabel>
              <Input type="text" name="name" value={order?.name} readOnly />
            </FormControl>
            <FormControl>
              <FormLabel color={theme.color.tertiary}>Telefone</FormLabel>
              <Input type="text" name="phone" value={order?.phone} readOnly />
            </FormControl>
          </SimpleGrid>
          <Text fontSize="2rem" fontWeight="600" color={theme.color.secondary}>
            Cliente
          </Text>
          <SimpleGrid
            alignItems="center"
            columns={{ base: 1, sm: 2, md: 2, lg: 3, xl: 4 }}
            spacing="1rem"
          >
            <FormControl>
              <FormLabel color={theme.color.tertiary}>E-mail</FormLabel>
              <Input
                type="text"
                name="email"
                value={order?.user?.email}
                readOnly
              />
            </FormControl>
            <FormControl>
              <FormLabel color={theme.color.tertiary}>Nome</FormLabel>
              <Input
                type="text"
                name="name"
                value={order?.user?.firstname}
                readOnly
              />
            </FormControl>
            <FormControl>
              <FormLabel color={theme.color.tertiary}>
                {order?.user?.type === "PF" ? "CPF" : "CNPJ"}
              </FormLabel>
              <Input
                type="text"
                name="document"
                value={order?.user?.document}
                as={ReactInputMask}
                mask={
                  order?.user?.type === "PF"
                    ? "999.999.999-99"
                    : "99.999.999/9999-99"
                }
                maskChar={null}
                readOnly
              />
            </FormControl>

            <FormControl>
              <FormLabel color={theme.color.tertiary}>Telefone</FormLabel>
              <Input
                type="text"
                name="phone"
                value={order?.user?.phone}
                readOnly
              />
            </FormControl>
          </SimpleGrid>
        </Flex>

        <Flex
          w="100%"
          h="full"
          p="2rem"
          display="flex"
          flexDirection="column"
          gap="1rem"
        >
          <Text fontSize="2rem" fontWeight="600" color={theme.color.secondary}>
            Endereço do cliente
          </Text>

          <SimpleGrid
            alignItems="center"
            columns={{ base: 1, sm: 2, md: 2, lg: 3, xl: 3 }}
            spacing="1rem"
          >
            <FormControl>
              <FormLabel color={theme.color.tertiary}>CEP</FormLabel>
              <Input
                type="text"
                name="postcode"
                value={order?.user?.postcode}
                readOnly
              />
            </FormControl>
            <FormControl>
              <FormLabel color={theme.color.tertiary}>Endereço</FormLabel>
              <Input
                type="text"
                name="address1"
                value={order?.user?.address1}
                readOnly
              />
            </FormControl>
            <FormControl>
              <FormLabel color={theme.color.tertiary}>Cidade</FormLabel>
              <Input
                type="text"
                name="city"
                value={order?.user?.city}
                readOnly
              />
            </FormControl>
            <FormControl>
              <FormLabel color={theme.color.tertiary}>Bairro</FormLabel>
              <Input
                type="text"
                name="address2"
                value={order?.user?.address2}
                readOnly
              />
            </FormControl>
            <FormControl>
              <FormLabel color={theme.color.tertiary}>Número</FormLabel>
              <Input
                type="text"
                name="addressNumber"
                value={order?.user?.addressNumber}
                readOnly
              />
            </FormControl>
            <FormControl>
              <FormLabel color={theme.color.tertiary}>Complemento</FormLabel>
              <Input
                type="text"
                name="addressComplement"
                value={order?.user?.addressComplement}
                readOnly
              />
            </FormControl>
          </SimpleGrid>
        </Flex>

        <Flex
          w="100%"
          h="full"
          p="2rem"
          display="flex"
          flexDirection="column"
          gap="1rem"
        >
          <Text fontSize="2rem" fontWeight="600" color={theme.color.secondary}>
            Pagamento
          </Text>

          <SimpleGrid
            alignItems="center"
            columns={{ base: 1, sm: 2, md: 2, lg: 3, xl: 4 }}
            spacing="1rem"
          >
            <FormControl>
              <FormLabel color={theme.color.tertiary}>
                Metodo de pagamento
              </FormLabel>
              <Input
                type="text"
                name="paymentMethod"
                value={order?.paymentMethodFormated}
                readOnly
              />
            </FormControl>
            <FormControl>
              <FormLabel color={theme.color.tertiary}>Status</FormLabel>
              <Input
                type="text"
                name="status"
                value={order?.statusFormated}
                readOnly
              />
            </FormControl>

            <FormControl>
              <FormLabel color={theme.color.tertiary}>
                Criação do pagamento
              </FormLabel>
              <Input
                type="text"
                name="createdAt"
                value={order?.createdAtFormated}
                readOnly
              />
            </FormControl>

            <FormControl>
              <FormLabel color={theme.color.tertiary}>
                Pagamento realizado em
              </FormLabel>
              <Input
                type="text"
                name="postcode"
                value={order?.paidAtFormated}
                readOnly
              />
            </FormControl>

            {order?.totals && (
              <Fragment>
                <FormControl>
                  <FormLabel color={theme.color.tertiary}>Impostos</FormLabel>
                  <Input
                    type="text"
                    name="total"
                    value={order?.totals?.feeFormated}
                    readOnly
                  />
                </FormControl>

                <FormControl>
                  <FormLabel color={theme.color.tertiary}>Descontos</FormLabel>
                  <Input
                    type="text"
                    name="total"
                    value={order?.totals?.discountFormated}
                    readOnly
                  />
                </FormControl>

                <FormControl>
                  <FormLabel color={theme.color.tertiary}>SubTotal</FormLabel>
                  <Input
                    type="text"
                    name="total"
                    value={order?.totals?.rateAmountFormated}
                    readOnly
                  />
                </FormControl>

                <FormControl>
                  <FormLabel color={theme.color.tertiary}>Valor</FormLabel>
                  <Input
                    type="text"
                    name="total"
                    value={order?.totals?.totalFormated}
                    readOnly
                  />
                </FormControl>
              </Fragment>
            )}
            {order?.paymentMethod === "pix" && (
              <FormControl>
                <FormLabel color={theme.color.tertiary}>Tx ID</FormLabel>
                <Input
                  type="text"
                  name="paymentMethod"
                  value={order?.pix?.txId}
                  readOnly
                />
              </FormControl>
            )}
          </SimpleGrid>
        </Flex>
      </Flex>

      {order?.type === "charge" && (
        <Flex
          w="100%"
          h="full"
          p="2rem"
          display="flex"
          flexDirection="column"
          gap="1rem"
        >
          <Text fontSize="2rem" fontWeight="600" color={theme.color.secondary}>
            Cobranças vinculadas a este pagamento
          </Text>

          <DataTable
            checkboxSelection={false}
            rows={order?.chargesArr || []}
            columns={columns}
            loading={loading}
          />
        </Flex>
      )}

      {order?.type === "balance" && (
        <Flex
          w="100%"
          h="full"
          p="2rem"
          display="flex"
          flexDirection="column"
          gap="1rem"
        >
          <Text fontSize="2rem" fontWeight="600" color={theme.color.secondary}>
            Informações do depósito
          </Text>

          <SimpleGrid
            alignItems="center"
            columns={{ base: 1, sm: 2, md: 2, lg: 3, xl: 3 }}
            spacing="1rem"
          >
            <FormControl>
              <FormLabel color={theme.color.tertiary}>txId</FormLabel>
              <Input
                type="text"
                name="txId"
                value={order?.pix?.txId}
                readOnly
              />
            </FormControl>

            <FormControl>
              <FormLabel color={theme.color.tertiary}>Valor</FormLabel>
              <Input
                type="text"
                name="value"
                value={Formatter.formatCurrency((order?.pix?.value || 0) * 100)}
                readOnly
              />
            </FormControl>

            <FormControl>
              <FormLabel color={theme.color.tertiary}>Situação</FormLabel>
              <Input
                type="text"
                name="status"
                value={order?.pix?.status}
                readOnly
              />
            </FormControl>

            <FormControl>
              <FormLabel color={theme.color.tertiary}>
                Data de criação
              </FormLabel>
              <Input
                type="text"
                name="createdAt"
                value={
                  order?.pix?.createdAt &&
                  format(order?.pix?.createdAt, "dd/MM/yyyy HH:mm:ss")
                }
                readOnly
              />
            </FormControl>

            <FormControl>
              <FormLabel color={theme.color.tertiary}>
                Data de expiração
              </FormLabel>
              <Input
                type="text"
                name="expiresAt"
                value={
                  order?.pix?.expiresAt &&
                  format(order.pix.expiresAt, "dd/MM/yyyy HH:mm:ss")
                }
                readOnly
              />
            </FormControl>

            <FormControl>
              <FormLabel color={theme.color.tertiary}>
                Data do pagamento
              </FormLabel>
              <Input
                type="text"
                name="paidAt"
                value={
                  order?.pix?.paidAt
                    ? format(order.pix.paidAt, "dd/MM/yyyy HH:mm:ss")
                    : undefined
                }
                readOnly
              />
            </FormControl>
          </SimpleGrid>
        </Flex>
      )}
    </Layout>
  )
}
